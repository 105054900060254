import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Badge,
  AppApprovalNotification,
  NewAccountNotification
} from 'Components'
import { FiBell, FiSettings } from 'react-icons/fi'
import { useSelector } from 'react-redux'

export const NotificationsMenu = () => {
  const navigate = useNavigate()
  let notifications = useSelector((state) => state.auth.account.userNotifications)
  notifications = (notifications || [])
    .map((notification) => {
      let component
      if (notification.type === 'APP_APPROVAL') component = AppApprovalNotification
      if (notification.type === 'NEW_ACCOUNT') component = NewAccountNotification
      return {
        ...notification,
        component
      }
    })
    .filter((notification) => notification.component)
  const unreadCount = notifications.filter((n) => !n.isRead).length
  const [open, setOpen] = React.useState(false)

  return (
    <DropdownMenu
      open={open}
      onOpenChange={(newOpen) => {
        if (notifications.length === 0) return
        setOpen(newOpen)
      }}
    >
      <DropdownMenuTrigger
        className={`focus:outline-none relative p-2 rounded-lg hover:bg-accent/50 transition-colors
          ${notifications.length === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        <FiBell size={20} className="stroke-[1.5px]" />
        {unreadCount > 0 && (
          <Badge
            className="absolute -top-1 -right-1 bg-destructive text-destructive-foreground w-4 h-4 
              flex items-center justify-center rounded-full text-[10px] font-medium p-0"
          >
            {unreadCount}
          </Badge>
        )}
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end" className="w-96 rounded-xl shadow-lg" sideOffset={8}>
        <div className="flex items-center justify-between px-4 py-3 border-b">
          <div className="flex items-center gap-3">
            <h3 className="font-medium">Notificări</h3>
            <Badge variant="secondary" className="bg-zinc-200">
              {notifications.length} notificări
            </Badge>
          </div>
          <button
            onClick={() => {
              setOpen(false)
              navigate('/settings/notifications')
            }}
            className="p-1.5 hover:bg-accent rounded-md transition-colors"
            title="Setări notificări"
          >
            <FiSettings size={18} className="text-muted-foreground" />
          </button>
        </div>

        {notifications.length === 0 ? (
          <div className="py-8 px-4 text-center text-muted-foreground text-sm">Nu aveți notificări</div>
        ) : (
          <div className="max-h-[400px] overflow-y-auto">
            {notifications.map((notification, index) => {
              const notificationData = { ...notification.data, id: notification.id, createdTs: notification.createdTs }
              return (
                <React.Fragment key={notification.id}>
                  <DropdownMenuItem
                    className={`px-4 py-2 hover:bg-accent focus:bg-accent
                    ${!notification.isRead ? 'bg-accent/40' : ''}`}
                  >
                    <notification.component {...notificationData} />
                  </DropdownMenuItem>
                  {index < notifications.length - 1 && <DropdownMenuSeparator className="my-0 pointer-events-none" />}
                </React.Fragment>
              )
            })}
          </div>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
