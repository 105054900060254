import { getAuthTokensFields, getAccountFields, sessionFields, activityLogFields } from 'API'

export const buildMeQuery = (fields = getAccountFields()) => `
  query getMe {
    getMe ${fields}
  }`

export const logoutQuery = `
  mutation logout($allOtherSessions: Boolean $jwtId: ID) {
    logout(allOtherSessions: $allOtherSessions, jwtId: $jwtId)
  }`

export const refreshTokensQuery = `
  mutation refreshTokens {
    refreshTokens {
      accessToken
      accessTokenExpiresTs
    }
  }`

export const authenticateWithTokenQuery = `
  mutation authenticateWithToken($token: ID!) {
    authenticateWithToken(token: $token) ${getAuthTokensFields}
  }`

export const sendEmailAuthCodeQuery = `
  mutation sendEmailAuthCode($email: String!) {
    sendEmailAuthCode(email: $email)
  }`

export const loginWithEmailCodeQuery = `
  mutation loginWithEmailCode($email: String!, $pin: String!) {
    loginWithEmailCode(email: $email, pin: $pin) ${getAuthTokensFields}
  }`

export const saveUserProfileQuery = `
  mutation saveUserProfile($userData: UserPayload!) {
    saveUserProfile(userData: $userData) ${getAuthTokensFields}
  }`

export const acceptInvitationQuery = `
  mutation acceptInvitation {
    acceptInvitation ${getAuthTokensFields}
  }`

export const denyInvitationQuery = `
  mutation denyInvitation {
    denyInvitation ${getAuthTokensFields}
  }`

export const getUserSessionsQuery = `
  query getUserSessions {
    getUserSessions ${sessionFields}
  }`

export const getUserActivityLogsQuery = `
  query getUserActivityLogs {
    getUserActivityLogs ${activityLogFields}
  }`

export const getEntityActivityLogsQuery = `
  query getEntityActivityLogs {
    getEntityActivityLogs ${activityLogFields}
  }`

export const readNotificationsQuery = `
  mutation readNotifications($ids: [ID]!) {
    readNotifications(ids: $ids)
  }`

export const deleteNotificationsQuery = `
  mutation deleteNotifications($ids: [ID]!) {
    deleteNotifications(ids: $ids)
  }`
