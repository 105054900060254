{
  "name": "app-factcurier",
  "version": "0.4.13",
  "private": true,
  "type": "module",
  "engines": {
    "node": "^20.x",
    "npm": "^10.x"
  },
  "scripts": {
    "clean": "rm -rf .parcel-cache && rm -rf dist && rm -rf build",
    "start:admin": "parcel src/site-admin/admin.html --port 3380",
    "start:dev": "parcel src/site-dev/dev.html --port 3381",
    "start:app": "parcel src/site-app/app.html --port 3382",
    "start:test": "parcel src/site-common/test.html --port 3388",
    "admin": "rm -rf .parcel-cache && parcel src/site-admin/admin.html --port 3380",
    "dev": "rm -rf .parcel-cache && parcel src/site-dev/dev.html --port 3381",
    "app": "rm -rf .parcel-cache && parcel src/site-app/app.html --port 3382",
    "dev:test": "rm -rf .parcel-cache && parcel src/site-common/test.html --port 3388",
    "run-build:admin": "parcel build src/site-admin/admin.html --dist-dir dist",
    "run-build:dev": "parcel build src/site-dev/dev.html --dist-dir dist",
    "run-build:app": "parcel build src/site-app/app.html --dist-dir dist",
    "build:admin": "parcel build src/site-admin/admin.html --dist-dir dist",
    "build:dev": "parcel build src/site-dev/dev.html --dist-dir dist",
    "build:app": "parcel build src/site-app/app.html --dist-dir dist",
    "build:test": "parcel build src/site-common/test.html --dist-dir dist",
    "lint": "eslint \"src/**/*.js\"",
    "prepare": "husky",
    "test": "echo test"
  },
  "alias": {
    "API": "./src/config/api.js",
    "Constants": "./src/config/constants.js",
    "Utils": "./src/config/utils.js",
    "Hooks": "./src/config/hooks.js",
    "Store": "./src/config/store.js",
    "Components": "./src/config/components.js"
  },
  "dependencies": {
    "@babel/plugin-proposal-private-property-in-object": "^7.21.11",
    "@emotion/react": "^11.13.3",
    "@emotion/styled": "^11.13.0",
    "@hookform/resolvers": "^3.9.1",
    "@parcel/packager-raw-url": "^2.13.0",
    "@parcel/transformer-postcss": "^2.12.0",
    "@parcel/transformer-sass": "^2.13.0",
    "@parcel/transformer-webmanifest": "^2.13.0",
    "@parcel/watcher": "^2.5.0",
    "@radix-ui/react-avatar": "^1.1.1",
    "@radix-ui/react-checkbox": "^1.1.2",
    "@radix-ui/react-dialog": "^1.1.2",
    "@radix-ui/react-dropdown-menu": "^2.1.2",
    "@radix-ui/react-icons": "^1.3.2",
    "@radix-ui/react-label": "^2.1.0",
    "@radix-ui/react-popover": "^1.1.2",
    "@radix-ui/react-select": "^2.1.2",
    "@radix-ui/react-separator": "^1.1.0",
    "@radix-ui/react-slot": "^1.1.0",
    "@radix-ui/react-switch": "^1.1.1",
    "@radix-ui/react-tabs": "^1.1.1",
    "@radix-ui/react-toast": "^1.2.2",
    "@reduxjs/toolkit": "^2.3.0",
    "@shadcn/ui": "^0.0.4",
    "autoprefixer": "^10.4.20",
    "buffer": "^6.0.3",
    "class-variance-authority": "^0.7.0",
    "clsx": "^2.1.1",
    "cmdk": "^1.0.4",
    "dayjs": "^1.11.13",
    "dompurify": "^3.2.0",
    "eslint": "^8.57.1",
    "eslint-config-prettier": "^9.1.0",
    "eslint-config-react-app": "^7.0.1",
    "eslint-plugin-n": "^17.13.2",
    "events": "^3.3.0",
    "filepond": "^4.32.1",
    "filepond-plugin-file-validate-size": "^2.2.8",
    "filepond-plugin-file-validate-type": "^1.2.9",
    "filepond-plugin-image-exif-orientation": "^1.0.11",
    "filepond-plugin-image-preview": "^4.6.12",
    "filepond-plugin-image-validate-size": "^1.2.7",
    "framer-motion": "^11.11.17",
    "friendly-challenge": "^0.9.18",
    "html-to-text": "^9.0.5",
    "husky": "^9.1.6",
    "lightningcss": "^1.28.1",
    "lodash": "^4.17.21",
    "parcel": "^2.13.0",
    "parcel-reporter-static-files-copy": "^1.5.3",
    "postcss": "^8.4.49",
    "prettier": "^3.3.3",
    "process": "^0.11.10",
    "proxy-memoize": "^3.0.1",
    "pusher-js": "^8.4.0-rc2",
    "react": "^18.3.1",
    "react-dom": "^18.3.1",
    "react-google-recaptcha-v3": "^1.10.1",
    "react-hook-form": "^7.53.2",
    "react-icons": "^5.3.0",
    "react-redux": "^9.1.2",
    "react-router-dom": "^6.28.0",
    "tailwind-merge": "^2.5.4",
    "tailwindcss": "^3.4.15",
    "tailwindcss-animate": "^1.0.7",
    "util": "^0.12.5",
    "vaul": "^1.1.1",
    "zod": "^3.23.8"
  },
  "browserslist": "> 0.5%, last 2 versions, not dead",
  "targets": {
    "main": false
  },
  "optionalDependencies": {
    "@parcel/watcher-linux-x64-glibc": "^2.1.0",
    "@swc/core-linux-x64-gnu": "1.x",
    "lightningcss-linux-x64-gnu": "1.x"
  },
  "repository": {
    "type": "git",
    "url": "https://gitlab.com/factcurier/app-factcurier"
  }
}
